
import Slider from '../classes/slider.js';
import Swipe from '../classes/Swipe.js';

export default class Homeslider {
    
    static get selector() {
        return '.slider';
    }

    constructor(el) {
        this.el = el;
        this.wrapper = el.querySelector('.wrapper')
        this.slides = this.getSlides();
        this.slider = new Slider(this.getSliderConfig(), this.slides);
        this.bullets = this.getBullets();
        this.active(this.bullets[0]);

        this.swipe = new Swipe(this.el);
        this.swipe.onRight(() => {
            clearInterval(this.timer);
            this.slider.previous();
        });
        this.swipe.onLeft(() => {
            clearInterval(this.timer);
            this.slider.next();
        });

        if(this.slides.length > 1) {

            this.timer = setInterval(() =>{
                if(window.blurred) return;
                this.slider.next();            
            }, 4000);
        }
        
        this.setEvents();
    }

    getSlides() {
        let slides = [];
        this.el.querySelectorAll('.sliderItem').forEach((element, index) => {
            slides.push(element);
        });
        return slides;
    }

    getSliderConfig() {
        return {
            currentClass: 'sliderItem--active',
            previousClass: 'sliderItem--prev',
            nextClass: 'sliderItem--next',

            loopSlides: true,
            animationDuration: 600,
        };
    }

    active(bullet) {
        this.desactiveAllAfter(bullet.index);
        this.desactive(bullet);
        setTimeout(() =>{

            if(bullet.isActive) return;

            bullet.el.classList.add('sliderBullet__item--animated')
            bullet.isActive = true;
        }, 100);

    }

    desactive(bullet) {
        if(!bullet.isActive) return;
        bullet.el.classList.remove('sliderBullet__item--animated')
        bullet.isActive = false;
    }

    desactiveAllAfter(index) {
        this.bullets.forEach(bullet => {
            if(bullet.index > index) this.desactive(bullet);
        });
    }

    getBullets() {
        let array = [];
        this.el.querySelectorAll('.sliderBullet__item').forEach((element, index) => {
            let bulletItem = {};
                bulletItem.el =  element;
                bulletItem.index = index;
                bulletItem.isActive = false;
            array.push(bulletItem);
        });
        return array;
    }

    setEvents() {
        this.slider.hook('slidechange', (e) => { this.e_slideChange(e) } );
        this.bullets.forEach(bullet => {
            bullet.el.addEventListener('click', (e) => { this.e_clickBullet(e, bullet); })
        });
    }
    e_clickBullet(e, bullet) {
        e.preventDefault();
        clearInterval(this.timer);
        this.slider.set(bullet.index);
    }

    e_slideChange(e){
        let bullet = this.bullets[e.current];
        this.active(bullet);
    }



}