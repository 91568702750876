/**
 * Polyfills
 */
import 'nodelist-foreach-polyfill';
import 'classlist-polyfill';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'element-remove';
import 'intersection-observer';
import 'polyfill-array-includes';

//import arrayFrom      from 'array-from';
//import clementClosest from 'element-closest';
//clementClosest(window);
//if (!Array.from) Array.from = arrayFrom;
import Inview             from './classes/Inview';
import Pluton             from 'whitecube-pluton';



class Batitec {
    constructor() {
        this.parts = new Pluton();
        this.inview = Inview;
    }
}

window.map = {
    isReady : false,
    
};
window.map.active = (e) => {
    console.log('Map is Ready');
    window.map.isReady = true;
    window.dispatchEvent(new CustomEvent('mapready', { detail: e }));
}  

window.addEventListener("load", function(event) {
    window.batitec = new Batitec();
});


document.querySelectorAll('a.smooth').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});