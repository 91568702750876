import Glide from '@glidejs/glide'

export default class ProjectSlider {
    
    static get selector() {
        return '.projectSlider';
    }

    constructor(el) {
        this.el = el;
        this.config = {
            gap: 0,
            autoplay: 4000,
            animationDuration: 600,
            classes:{
                activeNav: 'projectSlider__bullet--active'
            }
        }
        this.slider = new Glide(this.el, this.config).mount();
    }

}