

export default class Contactpage {
    
    static get selector() {
        return '.contactPage';
    }

    constructor(el) {
        this.el = el;
        this.selector = this.el.querySelector('.contactSelector__selector');

        this.contacts = this.getContacts();
        if(this.contacts.length){
            this.active(this.contacts[0]);
            this.setEvents();
        }
    }

    getContacts() {
        let elements = this.el.querySelectorAll('.contactSelector__item');
        let contacts = [];
        elements.forEach(element => {
            let contact = {};
                contact.isActive = false;
                contact.el = element; 

            contacts.push(contact);
        });

        return contacts;
    }

    active( contact ){
        if(contact.isActive) return;
        this.desactiveAllActive();
        contact.el.classList.add('contactSelector__item--active');
        contact.isActive = true;
    }

    desactive( contact ){
        if(!contact.isActive) return;
        contact.el.classList.remove('contactSelector__item--active');
        contact.isActive = false;
    }

    desactiveAllActive() {
        this.contacts.forEach( contact => {
            if(contact.isActive) this.desactive(contact);
        });
    }

   
    setEvents() {
        this.selector.addEventListener('change', this.e_select.bind(this));
    }

    e_select(e) {
        console.log(this.selector.value);
        let contact = this.contacts[parseInt(this.selector.value)];
        this.active(contact);
    }
}