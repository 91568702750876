
export default class Slider {

    constructor(setup, slides) {
        this.ui = this.getElements(setup);
        this.classes = this.getClasses(setup);
        this.options = this.getOptions(setup);
        
        this.slides = slides;
        this.states = this.getInitialStates();
        this.hooks = {};
        this.setEvents();
        this.set(0);
    }

    getElements(setup) {
        let elements = {};
        elements.prev = setup.prev ? this.getNodeList(setup.prev) : null;
        elements.next = setup.next ? this.getNodeList(setup.next) : null;
        elements.progress = setup.progress ? this.getNodeList(setup.progress) : null;
        elements.number = setup.number ? this.getNodeList(setup.number) : null;
        elements.total = setup.total ? this.getNodeList(setup.total) : null;
        return elements;
    }

    getClasses(setup) {
        let classes = {};
        classes.current = setup.currentClass ? setup.currentClass : 'active';
        classes.old = setup.oldClass ? setup.oldClass : null;
        classes.previous = setup.previousClass ? setup.previousClass : null;
        classes.next = setup.nextClass ? setup.nextClass : null;
        classes.prepPrevious = setup.preparePreviousClass ? setup.preparePreviousClass : null;
        classes.prepNext = setup.prepareNextClass ? setup.prepareNextClass : null;
        classes.disabled = setup.disabledClass ? setup.disabledClass : null;
        return classes;
    }

    getOptions(setup) {
        let options = {};
        options.direction = setup.progressDirection === 'horizontal' ? 'horizontal' : 'vertical';
        options.loop = setup.loopSlides === undefined ? false : (setup.loopSlides ? true : false);
        options.digits = setup.numberDigits === undefined ? 2 : parseInt(setup.numberDigits);
        options.duration = setup.animationDuration === undefined ? 0 : parseInt(setup.animationDuration);
        return options;
    }

    getInitialStates() {
        return {
            old: null,
            current: null,
            hasPrev: null,
            hasNext: null,
            progress: null,
            lastTime: null
        };
    }

    setEvents() {
        if(this.ui.prev) this.ui.prev.forEach((el) => {el.addEventListener('click', (e) => {this.event_clickPrevious(e);}, false)});
        if(this.ui.next) this.ui.next.forEach((el) => {el.addEventListener('click', (e) => {this.event_clickNext(e);}, false)});
    }

    // EVENTS

    event_clickPrevious(e) {
        e.preventDefault();
        if(this.isBeforeAnimationEnd()) return;
        this.ui.prev.forEach((el) => {el.blur()});
        this.previous();
    }

    event_clickNext(e) {
        e.preventDefault();
        if(this.isBeforeAnimationEnd()) return;
        this.ui.next.forEach((el) => {el.blur()});
        this.next();
    }

    // API

    set(index) {
        if(this.states.current === index) return;
        this.setStates(index);
        this.setNavigation();
        this.setSlides();
        this.setSlideTime();
        this.emit('slidechange');
    }

    skip(amount) {
        let index = this.states.current + amount;
        if(!this.options.loop && (index < 0 || index > this.slides.length - 1)) return;
        this.set(this.getRelativeIndex(amount));
    }

    previous() {
        if(!this.options.loop && this.states.current == 0) return;
        this.set(this.getRelativeIndex(-1));
    }

    next() {
        if(!this.options.loop && this.states.current == (this.slides.length - 1)) return;
        this.set(this.getRelativeIndex(1));
    }

    hook(event, callback) {
        if(this.hooks[event] === undefined) {
            this.hooks[event] = [];
        }
        this.hooks[event].push(callback);
    }

    // BEHAVIOR

    setStates(index) {
        this.states.old = this.states.current;
        this.states.current = index;
        this.states.hasPrev = (index > 0);
        this.states.hasAntePrev = ((index - 1) > 0);
        this.states.hasNext = (index < (this.slides.length - 1));
        this.states.hasPostNext = (index < (this.slides.length - 2));
        this.states.progress = this.slides.length > 1 ? ((index + 1) / this.slides.length) : 1;
    }

    setNavigation() {
        this.setButtonState('prev', this.states.hasPrev);
        this.setButtonState('next', this.states.hasNext);
        this.setNumber();
        this.setTotal();
        this.setProgress();
    }

    setSlides() {
        for (var i = this.slides.length - 1; i >= 0; i--) {
            this.setSlideState(this.slides[i], i);
        }
    }

    setSlideTime(time) {
        this.states.lastTime = time ? time : Date.now();
    }

    setButtonState(direction, show) {
        if(this.options.loop) return;
        if(!this.classes.disabled || !this.ui[direction]) return;
        if(show) return this.ui[direction].forEach((el) => {el.classList.remove(this.classes.disabled)});
        this.ui[direction].forEach((el) => {el.classList.add(this.classes.disabled)});
    }

    setNumber() {
        if(!this.ui.number) return;
        this.ui.number.forEach((el) => {el.innerHTML = this.getFormattedNumber(this.states.current + 1)});
    }

    setTotal() {
        if(!this.ui.total) return;
        this.ui.total.forEach((el) => {el.innerHTML = this.getFormattedNumber(this.slides.length)});
    }

    setProgress() {
        if(!this.ui.progress) return;
        let translate = (1 - this.states.progress) * 100,
            transform = (this.options.direction == 'horizontal') ? '-' + translate + '%,0,0' : '0,' + translate + '%,0';
        this.ui.progress.forEach((el) => {el.setAttribute('style', 'transform:translate3d(' + transform + ')')});
    }

    setSlideState(slide, index) {
        if(!Array.from(slide).length) return this.setSlideElementState(slide, this.getStateClasses(index));
        for (var i = slide.length - 1; i >= 0; i--) {
            this.setSlideElementState(slide[i], this.getStateClasses(index));
        }
    }

    setSlideElementState(element, classes) {
        this.resetSlideElementState(element);
        // Calling add(...classes) once would be better, but IE does not support it.
        for (var i = classes.length - 1; i >= 0; i--) {
            element.classList.add(classes[i]);
        }
    }

    resetSlideElementState(element) {
        // Calling remove() once would be better, but IE does not support multiple classes.
        element.classList.remove(this.classes.current);
        element.classList.remove(this.classes.old);
        element.classList.remove(this.classes.previous);
        element.classList.remove(this.classes.next);
        element.classList.remove(this.classes.prepPrevious);
        element.classList.remove(this.classes.prepNext);
    }

    emit(event) {
        if(!this.hooks[event]) return;
        for (var i = 0; i < this.hooks[event].length; i++) {
            this.hooks[event][i](this.states, this.slides[this.states.current]);
        }
    }

    // HELPERS

    getRelativeIndex(offset) {
        let value, direction = offset < 0 ? -1 : 1;
        offset = Math.abs(offset) > (this.slides.length - 1) ? (Math.abs(offset) % (this.slides.length - 1)) * direction: offset;
        value = this.states.current + offset;
        if(value < 0) return this.slides.length + value;
        if(value > (this.slides.length - 1)) return value - this.slides.length;
        return value;
    }

    getFormattedNumber(integer) {
        let string = integer.toString(), pow = this.options.digits;
        if(pow <= 0) return string;
        while(pow > 0) {
            if(integer >= Math.pow(10, --pow)) return string;
            string = '0' + string;
        }
        return string;
    }

    getStateClasses(index) {
        let classes = [];
        if(this.states.current === index) {
            // given index is current, so it could not have any other class.
            return [this.classes.current];
        }
        if(this.classes.old && this.states.old === index) {
            // slider can add an "old" class and index is the previously current slide.
            classes.push(this.classes.old);
        }
        if(this.classes.previous && (this.options.loop || this.states.hasPrev) && this.getRelativeIndex(-1) === index) {
            // slider can add an "prev" class, index is (current - 1), there are items 
            // to show on the left or slider is in "loop" mode.
            classes.push(this.classes.previous);
        }
        if(this.classes.next && (this.options.loop || this.states.hasNext) && this.getRelativeIndex(1) === index) {
            // slider can add an "next" class, index is (current + 1), there are items 
            // to show on the right or slider is in "loop" mode.
            classes.push(this.classes.next);
        }
        if(this.classes.prepPrevious && ((this.options.loop && this.slides.length > 4) || this.states.hasAntePrev) && this.getRelativeIndex(-2) === index) {
            // slider can add an "prepare previous" class, index is (current - 2), there are items 
            // to show on that index or slider is in "loop" mode and there are enough items to show.
            classes.push(this.classes.prepPrevious);
        }
        if(this.classes.prepNext && ((this.options.loop && this.slides.length > 4) || this.states.hasPostNext) && this.getRelativeIndex(2) === index) {
            // slider can add an "prepare next" class, index is (current + 2), there are items 
            // to show on that index or slider is in "loop" mode and there are enough items to show.
            classes.push(this.classes.prepNext);
        }
        return classes;
    }

    isBeforeAnimationEnd(Override) {
        let time = Date.now();
        if(this.states.lastTime === null || (this.states.lastTime + this.options.duration) < time) {
            return false;
        }
        return true;
    }

    getNodeList(element) {
        if(Array.from(element).length && element.length) return element;
        if(element) return [element];
        return null;
    }

}