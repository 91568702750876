
export default class MainMenu {
    
    static get selector() {
        return '.mainMenu';
    }

    constructor(el) {
        this.el = el;

        this.searchButton = this.el.querySelector('.search');
        this.searchMobileButton = document.querySelector('.searchMobileButton');

        this.dropdownButton = this.el.querySelector('.dropdown');
        this.dropdownMenu = this.dropdownButton.parentNode.querySelector('ul');

        this.searchBar = document.querySelector('.header__search');
        this.burger = window.document.querySelector('.gotomenu');

        this.setEvents();
        this.isSearchActive = false;
    }

    toggleSearchBar() {
        console.log('toggle');
        if(this.isSearchActive) this.desactiveSearchBar();
        else this.activeSearchBar();
    }

    open() {
        if(this.isOpen) return;
        window.scrollTo(0,0);
        document.body.classList.add('menuOpen');
        document.body.style.height = window.outerHeight+'px';
        this.desactiveSearchBar();
        this.isOpen = true;
    }

    close() {
        if(!this.isOpen) return;
        document.body.classList.remove('menuOpen');
        document.body.style.height = "";
        this.isOpen = false;
    }

    activeSearchBar() {
        if(this.isSearchActive) return;
        this.close();
        this.searchBar.classList.add('header__search--active')
        this.isSearchActive = true;

    }

    desactiveSearchBar() {
        if(!this.isSearchActive) return;
        this.searchBar.classList.remove('header__search--active')
        this.isSearchActive = false;
    }


    setEvents() {
        document.addEventListener('click', (e) => { this.e_clickOutside(e) }, false);

        this.dropdownButton.addEventListener('click', (e) => { this.e_clickDropDown(e) });
        
        this.searchButton.addEventListener('click', (e) => { this.e_clickSearch(e) });
        this.searchMobileButton.addEventListener('click', (e) => { this.e_clickSearch(e) });       

        this.dropdownMenu.addEventListener('mouseenter', this.e_mouseIn.bind(this));
        this.dropdownMenu.addEventListener('mouseleave', this.e_mouseOut.bind(this));

        this.burger.addEventListener('click', this.e_clickBurger.bind(this));

    }

    e_clickBurger(e) {
        e.preventDefault();
        e.target.blur();

        
        if(!this.isOpen) this.open();
        else this.close();
    }
        
    e_clickOutside(event) {

        var isClickInside = this.searchBar.contains(event.target);
        var isClickButton = this.searchButton.contains(event.target);
        var isClickMobileButton = this.searchMobileButton.contains(event.target);

        if (!isClickInside && !isClickButton && !isClickMobileButton && this.isSearchActive ) {
            this.desactiveSearchBar();
        }

    }
    e_clickDropDown(e){
        e.preventDefault();
    }

    e_clickSearch(e){
        e.preventDefault();
        this.toggleSearchBar();
        
    }

    e_mouseIn(e) {
        console.l
        this.dropdownButton.classList.add('active');
    }

    e_mouseOut() {
        this.dropdownButton.classList.remove('active');

    }





}