export default class Swipe {
    constructor(el, avoidScroll = 'none') {
        this.x = null;
        this.y = null;
        this.el = typeof(el) === 'string' ? document.querySelector(el) : el;
        this.blockScroll = avoidScroll; // none, horizontal, vertical, all

        this.setEvents();
    }

    resetValue() {
        this.x = null;
        this.y = null;
    }

    /**
     * Events
     */
    setEvents() {
        this.el.addEventListener('touchstart', (e) => { this.e_touchStart(e); }, {passive: true});  
        this.el.addEventListener('touchmove',  (e) => { this.e_touchMove(e); }, {passive: true});
    }

    e_touchStart(e) {
        this.x = e.touches[0].clientX;
        this.y = e.touches[0].clientY;
    }

    e_touchMove(e) {
        //e.preventDefault();
        if(this.blockScroll === 'all') e.preventDefault();
        
        if ( ! this.x || ! this.y ) return;

        let xDiff = this.x - e.touches[0].clientX;
        let yDiff = this.y - e.touches[0].clientY;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) { 
            if(this.blockScroll === 'horizontal') e.preventDefault();
            if (xDiff > 0) this.onLeft();
            else this.onRight(); 
        }
        this.resetValue();
    }

    /**
     * Callback
     */

    onLeft(callback) {
        this.onLeft = callback;
        return this;
    }

    onRight(callback) {
        this.onRight = callback;
        return this;
    }


}