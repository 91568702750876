export default class AboutVideo {

	constructor(el) {
        this.el = el;
        this.player = null;
        this.playerEl = this.el.querySelector('.aboutVideo__player');
        this.id = this.el.getAttribute('data-video-id');
        this.button = this.el.querySelector('.aboutVideo__playButton');
		this.requireAPI();
		this.setEvents();
	}

	static get selector() {
		return '.aboutVideo';
	}

	requireAPI() {
		if(window.ytAPI) return;
		window.ytAPI = true;
        // 2. This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    buildPlayer() {
        this.player = new YT.Player(this.playerEl, {
            height: '100%',
            width: '100%',
            videoId: this.id,
            playerVars: {
                showinfo: 0,
                rel: 0
            },
            events: {
                'onReady': () => { this.button.addEventListener('click', this.e_clickPlay.bind(this)); },

                //'onStateChange': (e) => { this.e_stateChange(e); }
            }
        });
    }

    setEvents() {
        window.addEventListener('yt-api-ready', () => { this.buildPlayer() });
    }

    e_stateChange(event) {
        if (event.data == YT.PlayerState.PLAYING) {
            this.button.classList.add('aboutVideo__playButton--unactive');
        }
    }

    e_clickPlay(e) {
        e.preventDefault();
        this.player.playVideo();
        this.button.classList.add('aboutVideo__playButton--unactive');
    }

}

window.onYouTubeIframeAPIReady = () => {
    window.dispatchEvent(new Event('yt-api-ready'));
}