

export default class Contactpage {
    
    static get selector() {
        return '.contactPage__mapObject';
    }

    constructor(el) {
        this.el = el;
        this.map = null;
        this.isBuilded = false;
        this.lat = parseFloat(this.el.getAttribute('data-lat'));
        this.lon = parseFloat(this.el.getAttribute('data-lon'));
        this.setEvents();
        if(window.map.isReady) this.buildMap();
    }

    buildMap() {
        if(this.isBuilded) return;
        this.map = new google.maps.Map(this.el, {
            center: { lat: this.lat, lng: this.lon },
            zoom: 13,
            disableDefaultUI: true,
        });

        var icon = {

            path: "M22 0C9.9 0 0 9.9 0 22c0 11.8 20.3 39.9 21.2 41.1.2.3.5.4.8.4s.6-.2.8-.4C23.7 61.9 44 33.8 44 22 44 9.9 34.1 0 22 0zm0 35.4c-7.4 0-13.4-6-13.4-13.4S14.6 8.6 22 8.6s13.4 6 13.4 13.4-6 13.4-13.4 13.4z",
            fillColor: '#e72d2e',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 1,
            size: new google.maps.Size(44, 63.5),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(44/2,63.5),
        }

        
        var marker = new google.maps.Marker({
            position: { lat: this.lat, lng: this.lon },
            map: this.map,
            draggable: false,
            icon: icon
        });

        this.isBuilded = true;
    }

    setEvents() {
        window.addEventListener('mapready', (e) => { this.e_mapIsReady(); } )
    }
    e_mapIsReady() {
        this.buildMap();
    }
}