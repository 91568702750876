
import Slider from '../classes/slider.js';

export default class HomeTrust {
    
    static get selector() {
        return '.homeTrust';
    }

    constructor(el) {
        this.el = el;
        this.slides = this.getSlides();
        this.slider = new Slider(this.getSliderConfig(), this.slides);

            this.bullets = this.getBullets();
            this.active(this.bullets[0]);
            this.setEvents();

        if(this.slides.length > 1) {

            this.timer = setInterval(() =>{
                if(window.blurred) return;
                this.slider.next();
            }, 4000);
        };

    }

    getSlides() {
        let slides = [];
        this.el.querySelectorAll('.homeTrust__slide').forEach((element, index) => {
            slides.push(element);
        });
        return slides;
    }

    getSliderConfig() {
        return {
            currentClass: 'homeTrust__slide--active',
            previousClass: 'homeTrust__slide--prev',
            nextClass: 'homeTrust__slide--next',

            loopSlides: true,
            animationDuration: 400,
        };
    }

    active(bullet) {
        if(bullet.isActive) return;

        this.bullets.forEach(bullet => {
            this.desactive(bullet);
        });
        bullet.el.classList.add('homeTrust__sliderBulletItem--active')
        bullet.isActive = true;
    }

    desactive(bullet) {
        if(!bullet.isActive) return;
        bullet.el.classList.remove('homeTrust__sliderBulletItem--active')
        bullet.isActive = false;
    }
    getBullets() {
        let array = [];
        let els = this.el.querySelectorAll('.homeTrust__sliderBulletItem');

        els.forEach( (el, index) => {
            let bulletItem = {};
                bulletItem.el =  el;
                bulletItem.index = index;
                bulletItem.isActive = false
            array.push(bulletItem);
        });

        return array;
    }

    setEvents() {
        this.slider.hook('slidechange', (e) => { this.e_slideChange(e) } );
        this.bullets.forEach(bullet => {
            bullet.el.addEventListener('click', (e) => {  this.e_clickBullet(e, bullet) });
        });
    }

    e_clickBullet(e, bullet){
        e.preventDefault();
        clearInterval(this.timer);
        this.slider.set(bullet.index);
    }
    e_slideChange(e){
        this.active(this.bullets[e.current]);
    }



}