

export default class Contactpage {
    
    static get selector() {
        return '.contactPageOld';
    }

    constructor(el) {
        this.el = el;
        this.forms = this.getForms();
        console.log(this.forms);
        this.defaultActiveForm = 'contact';
        this.active(this.getActiveForm())
        this.setEvents();

    }

    getActiveForm() {
        let get = this.findGetParameter('form');
        let identifier = get ? get : this.defaultActiveForm;

        for (let index = 0; index < this.forms.length; index++) {
            const form = this.forms[index];
            if(form.id === identifier) return form;
        }
        return this.forms[0];
    }

    getForms() {
        let array = [];
        let forms = this.el.querySelectorAll('.contactPage__formItem')

        forms.forEach(formEl => {
            let form = {};
                form.el = formEl;
                form.id = formEl.getAttribute('id');
                form.link = this.el.querySelector('.contactPage__selectorItem[href="#' + form.id +'"]');
                form.isActive = false;
                array.push(form);
        });
        return array;
    }

    active(form) {
        if(form.isActive) return;
        this.forms.forEach(form => {
            this.desactive(form);
        });
        form.el.classList.add('contactPage__formItem--active');
        form.link.classList.add('contactPage__selectorItem--active');
        form.isActive = true;
    }

    desactive(form) {
        if(!form.isActive) return;
        form.el.classList.remove('contactPage__formItem--active');
        form.link.classList.remove('contactPage__selectorItem--active');
        form.isActive = false;
    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    setEvents() {
        this.forms.forEach(form => {
            form.link.addEventListener('click', (e) => { this.e_click(e, form); })
        });
    }

    e_click(e, form) {
        e.preventDefault();
        this.active(form);
    }
}