export default class Contactpage {
    
    static get selector() {
        return '.footer__newsletterform';
    }

    constructor(el) {
        if (!window.fetch) return;
        this.el = el;
        this.message = document.querySelector('.footer__text');
        this.setEvents();
    }
   

    setEvents() {
        this.el.addEventListener("submit", this.e_submit.bind(this) );
    }

    e_submit(e) {
        e.preventDefault();
        const fd = new FormData(this.el);
        fetch(window.location.href, {
            method: 'POST',
            body: fd,
            headers: {
                'Accept': 'application/json;charset=utf-8'
            }
        })
        .then(async (response) => {
            var contentType = response.headers.get("content-type");
            if(contentType && contentType.indexOf("application/json") !== -1) {
              const json = await response.json();
                if (json.success) {
                    this.message.classList.add('success');
                    this.message.classList.remove('error');
                    this.message.innerText = "Merci, vous êtes inscrit !";
                }
                else {
                    this.message.classList.add('error');
                    this.message.classList.remove('success');
                    this.message.innerText = "Veuillez introduire un email valide !";
                }
            } else {
                this.message.classList.add('error');
                this.message.classList.remove('success');
                this.message.innerText = "Une erreur est survenue: erreur API";
                console.log("Oops, nous n'avons pas du JSON!");
            }
          });
    }
}