
export default class FeaturedProject {
    
    static get selector() {
        return '.featuredProject';
    }

    constructor(el) {
        this.el = el;
        this.sections = this.getSections();
        this.active(this.sections[0]);
        this.setEvents();

    }


    setEvents() {
        this.sections.forEach( section => {
            section.link.addEventListener('click', (e)=> { 
                e.preventDefault()
                this.active(section); 
            })
        });
    }

    getSections() {
        let array = [];
        let els = this.el.querySelectorAll('.featuredProject__content');
        els.forEach(element => {
            let section = {};
                section.el = element;
                section.link = element.querySelector('.featuredProject__title');
                section.target = section.link.getAttribute('href');
                section.list = this.el.querySelector(section.target);
                section.isActive = false;
                array.push(section);
        });
        return array;
    }
    

    active(section) {
        if(section.isActive) return;

        this.sections.forEach(section => {
            if(section.isActive) this.desactive(section);
        });
        section.el.classList.add('featuredProject__content--active');
        section.list.classList.add('featuredProject__section--active');

        section.isActive = true;
    }

    desactive(section) {
        if(!section.isActive) return;
        section.el.classList.remove('featuredProject__content--active');
        section.list.classList.remove('featuredProject__section--active');
        section.isActive = false;
    }

    e_clickDropDown(e){
        e.preventDefault();
    }



}