
import Slider from '../classes/slider.js';
import Swipe from '../classes/Swipe.js';

export default class AboutPhilosophy {
    
    static get selector() {
        return '.aboutPhilosophy';
    }

    constructor(el) {
        this.el = el;
        this.sliderEl = this.el.querySelector('.aboutPhilosophy__slider');
        this.slides = this.getSlides();
        if(this.slides.length > 1) {

            this.slider = new Slider(this.getSliderConfig(), this.slides);
            this.bullets = this.getBullets();

            this.active(this.bullets[0]);
            this.timer = setInterval(() =>{
                if(window.blurred) return;
                this.slider.next();
            }, 4000);
            this.setEvents();

            this.swipe = new Swipe(this.sliderEl);
            this.swipe.onRight(() => {
                clearInterval(this.timer);
                this.slider.previous();
            });
            this.swipe.onLeft(() => {
                clearInterval(this.timer);
                this.slider.next();
            });
        };
        

    }

    getSlides() {
        let slides = [];
        this.el.querySelectorAll('.aboutPhilosophy__slide').forEach((element, index) => {
            slides.push(element);
        });
        return slides;
    }

    getSliderConfig() {
        return {
            preparePreviousClass:  'aboutPhilosophy__slide--prePrev',
            previousClass:  'aboutPhilosophy__slide--prev',
            currentClass:   'aboutPhilosophy__slide--active',
            nextClass:      'aboutPhilosophy__slide--next',
            prepareNextClass:  'aboutPhilosophy__slide--preNext',
            loopSlides: true,
            animationDuration: 200,
        };
    }

    active(bullet) {
        if(bullet.isActive) return;

        this.bullets.forEach(bullet => {
            this.desactive(bullet);
        });
        bullet.el.classList.add('aboutPhilosophy__bullet--active')
        bullet.isActive = true;
    }

    desactive(bullet) {
        if(!bullet.isActive) return;
        bullet.el.classList.remove('aboutPhilosophy__bullet--active')
        bullet.isActive = false;
    }
    getBullets() {
        let array = [];
        let els = this.el.querySelectorAll('.aboutPhilosophy__bullet');

        els.forEach( (el, index) => {
            let bulletItem = {};
                bulletItem.el =  el;
                bulletItem.index = index;
                bulletItem.isActive = false
            array.push(bulletItem);
        });

        return array;
    }

    setEvents() {
        this.slider.hook('slidechange', (e) => { this.e_slideChange(e) } );
        this.bullets.forEach(bullet => {
            bullet.el.addEventListener('click', (e) => {  this.e_clickBullet(e, bullet) });
        });
    }

    e_clickBullet(e, bullet){
        e.preventDefault();
        clearInterval(this.timer);
        this.slider.set(bullet.index);
    }
    e_slideChange(e){
        this.active(this.bullets[e.current]);
    }

}