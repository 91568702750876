export default class Cookies {

    static get selector() {
      return '.cookies';
    }
    
    setupConfig(){
        this.cookieName = 'cookieconsent_status';
        this.cookieDuration = 365; //in days
        this.cookieValue = 'on';
    }    
    
    constructor(el) {
        this.setupConfig();

        this.el = el;
        if(this.el) {
            this.acceptButton = this.el.querySelector('.cookies__accept');
            this.isActive = this.el.classList.contains('cookies--active');
            if(this.checkCookie() == this.cookieValue) this.desactive();
            else this.active();
            this.setEvents();
        }
    }

    active() { 
        if(this.isActive) return;
        this.isActive = true;
        this.el.classList.add('cookies--active');
    }  

    desactive() {
        if(!this.isActive) return;
        this.isActive = false;
        this.el.classList.remove('cookies--active');
    }

    accept() {
        this.createCookie();
        this.desactive();
    }

    /**
     * Cookies
     */

    createCookie() {
        let date = new Date();
            date.setTime(date.getTime()+(this.cookieDuration*24*60*60*1000)); 
        let expires = "; expires="+date.toGMTString(); 
        document.cookie = this.cookieName+"="+this.cookieValue+expires+"; path=/"; 
    }

    eraseCookie() {
        createCookie(this.cookieName,"",-1);
    }

    checkCookie() {
        let nameEQ = this.cookieName + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length) ;
        }
        return null;
    }

    /**
     * Events
     */
    setEvents() {
        this.acceptButton.addEventListener('click', (e) => { this.e_click(e); }, false);
    }

    e_click(e) {
        e.preventDefault();
        e.target.blur();
        this.accept();
    }

}
